<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity, AuctionAssetBusListingItem } from '~/apiClient';

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity | AuctionAssetBusListingItem>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const isBadgeVisible = computed<boolean>(() => props.asset.toBeScraped);
</script>

<template>
    <UBadge v-if="isBadgeVisible" variant="solid" size="lg" class="flex gap-x-2 bg-red-600 text-white">
        <UIcon class="h-5 w-5" name="i-mdi-recycle" />
        {{ tt('asset.toBeScraped') }}
    </UBadge>
</template>
